@font-face {
  font-family: "BaiJamjuree-SemiBold";
  font-weight: 900;
  src: local("BaiJamjuree-SemiBold"),
    url(./assets/fonts/BaiJamjuree-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "BaiJamjuree-Medium";
  font-weight: 900;
  src: local("BaiJamjuree-Medium"),
    url(./assets/fonts/BaiJamjuree-Medium.ttf) format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: BaiJamjuree-SemiBold, BaiJamjuree-Medium;
}
